<template>
  <v-container fluid class="pt-0 align-start justify-start" fill-height>
    <v-overlay
        v-model="calculating"
        color="#121212"
        style="z-index: 999 !important;"
    >
      <v-progress-circular
          indeterminate
          color="secondary"
          v-if="calculating"
          :size="100"
          :width="10"
          style="position: relative; left: 50%; transform: translateX(-50%);"
      />
      <v-subheader class="white--text subtitle-1 mt-2 text-uppercase">
        {{ calculation_text }}
      </v-subheader>
    </v-overlay>
    <v-row style="flex: 1 0 100%;" class="py-0">
      <v-col cols="12" class="py-0">
        <v-card flat color="grey lighten-4">
          <v-card-text class="pa-0">
            <v-chip-group column>
              <v-chip label class="pa-5 white elevation-2">
                <v-icon left class="mr-3" color="error lighten-1" v-if="$vuetify.breakpoint.smAndUp">
                  mdi-account-supervisor-circle-outline
                </v-icon>
                {{ organization.short_name }}
              </v-chip>
              <v-chip label class="pa-5 white elevation-2">
                <v-icon left class="mr-3" color="purple lighten-1" v-if="$vuetify.breakpoint.smAndUp">mdi-clock-start
                </v-icon>
                <span class="text-capitalize">{{ current_month | formatDate('MMMM YYYY') }}</span>
              </v-chip>
              <v-chip label class="pa-5 white elevation-2" v-if="$vuetify.breakpoint.smAndUp">
                <v-icon left class="mr-3" color="blue lighten-1">mdi-account-outline</v-icon>
                {{ userName }}
              </v-chip>
            </v-chip-group>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row style="flex: 1 0 100%; height: 50px;">
      <v-col cols="12" md="12" class="pt-0 pb-0">
        <v-card rounded style="border: none" elevation="2">
          <v-toolbar tile elevation="0" outlined dense style="border: none;">
            <MainModal
                :main="{ component: 'LegacyFlat', title: 'Абонент (юридична особа)' }"
                :button="{
                            icon: false,
                            buttonClass: 'mr-2',
                            buttonIconClass: 'mr-2',
                            buttonColor: 'grey lighten-4',
                            buttonIcon: 'mdi-plus',
                            buttonName: 'Новий абонент',
                            buttonSmall: true,
                            buttonOutlined: false,
                            buttonDepressed: true,
                          }"
            />
            <v-toolbar-items class="shrink" v-if="$vuetify.breakpoint.smAndUp">
              <v-row class="pa- ma-0">
                <v-menu transition="slide-y-transition" bottom offset-y v-if="!organization.cabinet_only">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        text
                        tile
                        v-bind="attrs"
                        v-on="on"
                        min-width="100px"
                        height="100%"
                        small
                    >
                      <v-icon size="18" left color="teal" class="mr-2">mdi-book-open-outline</v-icon>
                      Операції
                    </v-btn>
                  </template>
                  <v-list class="subtitle-1" nav dense>
                    <v-list-item :to="{ name: 'GeneralSettings' }">
                      <v-list-item-icon>
                        <v-icon color="teal">mdi-card-bulleted-settings-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Загальні налаштування (покавартирний облік)</v-list-item-title>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item :to="{ name: 'AccountingGeneralSettings' }">
                      <v-list-item-icon>
                        <v-icon color="teal darken-1">mdi-card-bulleted-settings-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Загальні налаштування (бухгалтерський облік)</v-list-item-title>
                    </v-list-item>
                    <v-divider/>
                    <v-menu
                        transition="slide-y-transition"
                        bottom
                        offset-y
                    >
                      <template v-slot:activator="{ on, attrs, value }">
                        <v-list-item v-on="on" v-bind="attrs" class="d-flex align-center">
                          <v-list-item-icon class="my-0" style="align-self: center">
                            <v-icon color="grey darken-1">mdi-calendar-arrow-right</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Закрити/Відкрити місяць</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon>
                              {{ value ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                            </v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                      <v-list class="subtitle-1" nav dense>
                        <v-list-item @click="changeMonthQuestion(true)">
                          <v-list-item-icon>
                            <v-icon color="success">mdi-calendar-arrow-right</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Перейти на {{ next_month | formatDate('MMMM YYYY') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="changeMonthQuestion(false)">
                          <v-list-item-icon>
                            <v-icon color="error">mdi-calendar-arrow-left</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Відкрити {{ prev_month | formatDate('MMMM YYYY') }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list>
                </v-menu>
                <v-divider vertical class="grey lighten-3"/>
                <v-menu
                    transition="slide-y-transition"
                    bottom
                    offset-y
                    v-if="!organization.cabinet_only"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        text
                        tile
                        v-bind="attrs"
                        v-on="on"
                        min-width="100px"
                        height="100%"
                        small
                    >
                      <v-icon small left color="success darken-1" class="mr-2">mdi-book-open-outline</v-icon>
                      Внесення даних
                    </v-btn>
                  </template>
                  <v-list class="subtitle-1" nav dense>
                    <v-list-item :to="{ name: 'OddmentsDocuments'}">
                      <v-list-item-icon>
                        <v-icon>mdi-cash-100</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Внесення залишків</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item :to="{ name: 'OneTimeCharge'}">
                      <v-list-item-icon>
                        <v-icon color="primary">mdi-animation-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Одноразове нарахування</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item :to="{ name: 'Removal'}">
                      <v-list-item-icon>
                        <v-icon color="teal">mdi-home-remove-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Зняття</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item :to="{ name: 'PaymentDocuments'}">
                      <v-list-item-icon>
                        <v-icon color="success">mdi-cash-100</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Внесення оплат</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item :to="{ name: 'ReadingDocument'}">
                      <v-list-item-icon>
                        <v-icon color="primary">mdi-speedometer-medium</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Внесення показів</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item :to="{ name: 'FlatCounterAvgList'}">
                      <v-list-item-icon>
                        <v-icon color="secondary">mdi-speedometer</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Покази по середньому</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                  </v-list>
                </v-menu>
                <v-divider vertical class="grey lighten-3"/>
                <v-menu
                    transition="slide-y-transition"
                    bottom
                    offset-y
                    v-if="!organization.cabinet_only"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        text
                        tile
                        v-bind="attrs"
                        v-on="on"
                        min-width="100px"
                        height="100%"
                        small
                    >
                      <v-icon small left color="primary" class="mr-2">mdi-book-open-outline</v-icon>
                      Документи
                    </v-btn>
                  </template>
                  <v-list class="subtitle-1" nav dense>
                    <v-list-item :to="{ name: 'LegacyDocumentCrud'}">
                      <v-list-item-icon>
                        <v-icon color="teal">mdi-file-document-plus-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Пакетне створення/вилучення документів</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item :to="{ name: 'DocumentTaxBillExportToXml'}">
                      <v-list-item-icon>
                        <v-icon color="success">mdi-xml</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Вивантаження податкових накладних в xml</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item :to="{ name: 'PrintLegacyDocuments'}">
                      <v-list-item-icon>
                        <v-icon color="secondary">mdi-printer</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Пакетний друк документів</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item :to="{ name: 'LegacyDocumentSettings'}">
                      <v-list-item-icon>
                        <v-icon color="secondary">mdi-file-settings-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Налаштування створення та друку документів</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-divider vertical class="grey lighten-3"/>
                <v-menu
                    transition="slide-y-transition"
                    bottom
                    offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        text
                        tile
                        v-bind="attrs"
                        v-on="on"
                        min-width="100px"
                        height="100%"
                        small
                        style="border-right: 1px solid #ececec"
                    >
                      <v-icon small left color="primary lighten-1" class="mr-2">mdi-book-open-outline</v-icon>
                      Звіти
                    </v-btn>
                  </template>
                  <v-list class="subtitle-1" nav dense>
                    <v-list-item class="menu-item" :to="{ name: 'Reports', params: { report_name: 'general_report_legacy' } }">
                      <v-list-item-icon>
                        <v-icon size="22" color="success">mdi-finance</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Загальний звіт</v-list-item-title>
                    </v-list-item>
                    <v-divider class="menu-divider"/>
                    <v-subheader style="height: 25px">Детальні звіти</v-subheader>
                    <v-list-item class="menu-item"
                                 :to="{ name: 'Reports', params: { report_name: 'general_report_by_flat_legacy' } }">
                      <v-list-item-icon>
                        <v-icon size="22" color="teal darken-1">mdi-file-chart-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Оборотно-сальдова по будинках/квартирах</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="menu-item"
                                 :to="{ name: 'Reports', params: { report_name: 'general_report_by_flat_by_service_legacy' } }">
                      <v-list-item-icon>
                        <v-icon size="22" color="teal darken-1">mdi-file-chart-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Оборотно-сальдова по послугах та будинках/квартирах</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="menu-item"
                                 :to="{ name: 'Reports', params: { report_name: 'indicator_report_by_flat_legacy' } }">
                      <v-list-item-icon>
                        <v-icon size="22" color="red lighten-1">mdi-chart-box-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Відомість абонентів по якісно-кількісних показниках</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="menu-item"
                                 :to="{ name: 'Reports', params: { report_name: 'debt_group_report_by_flat_legacy' } }">
                      <v-list-item-icon>
                        <v-icon size="22" color="red lighten-1">mdi-account-cash</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Аналіз заборгованості</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="menu-item"
                                 :to="{ name: 'Reports', params: { report_name: 'debt_report_by_flat_legacy' } }">
                      <v-list-item-icon>
                        <v-icon size="22" color="red lighten-1">mdi-account-cash</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Звіт по боржниках</v-list-item-title>
                    </v-list-item>
                    <v-divider class="menu-divider"/>
                  </v-list>
                </v-menu>
              </v-row>
            </v-toolbar-items>
            <v-spacer/>
            <v-btn fab small text class="grey lighten-3 mr-2"
                   :to="{ name: 'Reports', params: { report_name: 'general_report_legacy' } }"
            >
              <v-icon color="grey darken-2">
                mdi-chart-box-outline
              </v-icon>
            </v-btn>
            <v-btn fab small text class="success" v-if="!simple_user && !organization.cabinet_only">
              <v-icon dark @click="questionForCalcByAll">mdi-calculator</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card>
      </v-col>
    </v-row>
    <v-row style="flex: 1 1 100%; height: calc(100% - 106px)">
      <v-col cols="12" md="12" class="py-1">
        <v-card class="fill-height">
          <v-card-title class="pt-1 px-1 pb-2">
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Пошук"
                color="grey darken-1"
                placeholder="Почніть вводити текст для пошуку"
                single-line
                hide-details
                class="px-4 mb-3"
                @input="searchFlatByText"
            ></v-text-field>
          </v-card-title>
          <div class="pt-1 px-4 pb-2">
            <div class="form-table-custom mt-0" style="width: 100%">
              <table style="width: 100%">
                <thead>
                <tr>
                  <th style="width: 90px">Особовий</th>
                  <th style="min-width: 300px">Найменування</th>
                  <th style="min-width: 300px">Адреса</th>
                  <th style="min-width: 90px">ЄДРПОУ</th>
                  <th style="min-width: 90px">ІПН</th>
                  <th style="min-width: 130px">Вид особи</th>
                  <th style="min-width: 130px">Ставка ПДВ</th>
                  <th style="min-width: 130px">Сальдо на початок</th>
                  <th style="min-width: 130px">Оплачено</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, idx) in legacy_flats" :key="idx" @click="navigateToWorkPlace(item.person_hash)">
                  <td style="width: 90px; text-align: left; padding-left: 10px !important;">
                    <v-icon size="18" color="teal mr-3">mdi-tag-multiple-outline</v-icon>
                    {{ item.person_id }}
                  </td>
                  <td style="width: 300px; text-align: left; padding-left: 10px !important;">{{
                      item.full_address
                    }}
                  </td>
                  <td
                      style="width: 300px; text-align: left; padding-left: 10px !important;"
                  >{{ item.fact_address }}
                  </td>
                  <td style="width: 90px;">{{ item.code }}</td>
                  <td>{{ item.ident_code }}</td>
                  <td>{{ item.is_legal_person ? 'Фізична особа' : 'Юридична особа' }}</td>
                  <td>{{ item.tax_name }}</td>
                  <td style="font-weight: 500;"
                      :class="[
                              {'error--text': item.start_balance > 0},
                              {'success--text': item.start_balance < 0}
                              ]"
                  >{{ item.start_balance | formatToFixed | formatNumber }}
                  </td>
                  <td>{{ item.pay | formatToFixed | formatNumber }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {FETCH_LEGACY_FLATS, SEARCH_LEGACY_FLAT_BY_TEXT} from "@/store/actions/flat";
import update from "@/mixins/update"
import {QUESTION_SHOW, STOP_ANSWER_LOADING} from "@/store/actions/question";
import {CALC_BY_ALL_LEGACY} from "@/store/actions/calculation";
import {ALERT_SHOW} from "@/store/actions/alert";
import {formatDate} from "@/filters";
import {addMothsToStringDate} from "@/utils/icons";
import {CLOSE_LEGACY_MONTH, OPEN_LEGACY_MONTH} from "@/store/actions/work_month";

const modalDeleteId = 'calc_by_all_legacy_modal'
const modalNextMonth = 'question_next_month_legacy'
const modalPrevMonth = 'question_prev_month_legacy'

export default {
  name: "HousePayDesktopLegacy",
  components: {
    MainModal: () => import("@/components/modal/MainModal")
  },
  mixins: [update],
  computed: {
    ...mapGetters({
      organization: 'getCurrentOrganization',
      current_month: 'getCurrentMonthLegacy',
      legacy_flats: 'getLegacyFlats',
      userName: 'getUserName',
      simple_user: 'simple_user',
      modalAnswer: 'question_answer',
      prev_month: 'getPrevMonthLegacy',
      next_month: 'getNextMonthLegacy',
    })
  },
  data() {
    return {
      search: '',
      calculating: false,
      question_watcher: null,
      calculation_text: 'Зачекайте доки завершиться розрахунок...',
    }
  },
  methods: {
    ...mapActions({
      fetchLegacyFlats: FETCH_LEGACY_FLATS,
      searchFlat: SEARCH_LEGACY_FLAT_BY_TEXT,
    }),
    searchFlatByText(e) {
      if (e && e.length > 1) {
        this.searchFlat(e)
      } else {
        this.fetchLegacyFlats()
      }
    },
    navigateToWorkPlace(person_hash) {
      this.$router.push(
          {name: 'LegacyWorkPlace', params: {person_hash: person_hash}}
      )
    },
    set_work_month(close) {
      const c_month = close
          ? addMothsToStringDate(this.current_month, 1)
          : addMothsToStringDate(this.current_month, -1)

      this.calculating = true
      this.calculation_text = `Виконується операція переходу на ${formatDate(c_month, 'MMMM YYYY')} місяць...`

      if (close) {
        this.$store.dispatch(CLOSE_LEGACY_MONTH)
            // .then((month) => {
            //   this.$ws.send_message(Object.assign({
            //     month: month,
            //     organization_id: this.organization.id,
            //     owner_id: this.userId
            //   }, {ws_type: 'month_change'}))
            // })
            .finally(() => {
              this.calculating = false
              this.$store.dispatch(STOP_ANSWER_LOADING)
            })
      } else {
        this.$store.dispatch(OPEN_LEGACY_MONTH)
            // .then((month) => {
            //   this.$ws.send_message(Object.assign({
            //     month: month,
            //     organization_id: this.organization.id,
            //     owner_id: this.userId
            //   }, {ws_type: 'month_change'}))
            // })
            .finally(() => {
              this.calculating = false
              this.$store.dispatch(STOP_ANSWER_LOADING)
            })
      }
    },
    changeMonthQuestion(next_month) {
      const title = next_month ? `Перейти на ${formatDate(this.next_month, 'MMMM YYYY')}` : `Відкрити ${formatDate(this.prev_month, 'MMMM YYYY')}`

      const payload = {
        text: title,
        accept_button: true,
        id: next_month ? modalNextMonth : modalPrevMonth
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    questionForCalcByAll() {
      const payload = {
        text: `Виконати розрахунок нарахувань,`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    watch_modal_answer() {
      this.question_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.calculating = true
                  this.calculation_text = 'Зачекайте доки завершиться розрахунок...'
                  this.$store.dispatch(CALC_BY_ALL_LEGACY)
                      .then(ok => {
                        if (ok) {
                          this.$store.commit(ALERT_SHOW, {text: 'Розрахунок завершено успішно', color: 'success'})
                          this.fetchLegacyFlats()
                        }
                      })
                      .finally(() => {
                        this.calculating = false
                      })
                }
              }

              if (payload.id === modalNextMonth) {
                if (payload.answer) {
                  this.set_work_month(true)
                }
              }

              if (payload.id === modalPrevMonth) {
                if (payload.answer) {
                  this.set_work_month(false)
                }
              }
            }
          }
      )
    },
  },
  mounted() {
    this.watch_modal_answer()
    this.fetchLegacyFlats()
  }
}
</script>

<style scoped lang="scss">
tbody {
  tr {
    &:hover {
      td {
        font-weight: 500;
      }
    }
    td {
      line-height: .94rem;
      cursor: pointer;
    }
  }
}
</style>